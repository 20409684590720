<template>
  <div>
    <div :key="item.sid" v-for="item in channels">
      <div>
        <div>{{ item.channel_name }}</div>
        <div>{{ item.lastMessageTime }}</div>
        <div>{{ item.company }}</div>
        <div>{{ item.usersKV }}</div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("chat", ["channelsSort", "channelsKV"]),
    channels() {
      return this.channelsSort.map(item => this.channelsKV[item]);
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="less">
.container {
  color: green;
}
</style>
